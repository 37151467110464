<template>

  <!-- Staff Directory View -->
  <j-page
    :title="pageTitle"
    :subtitle="pageSubtitle"
    :loading="loading"
    :left-cols="leftCols"
    fill
    id="staff-directory-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Search Bar -->
      <j-search
        :query="query.q"
        :open="searchMode"
        @expand="handleExpand"
        @search="handleSearch"
        @clear="handleClear"
      />

      <!-- Add Staff Button -->
      <j-button
        v-if="directoryMode && canAddStaff"
        type="icon"
        help="Add a staff member to the site"
        icon="plus"
        @action="handleAddStaff"
      />

    </template>

    <!-- Left - Masthead -->
    <template #left v-if="showMasthead">

      <!-- Heading -->
      <j-heading heading="Masthead" />

      <!-- Listing -->
      <MastheadEntry
        v-for="editor in editors.getModels()"
        :key="editor.tag19"
        :contact="editor"
        class="mb-4"
      />

      <!-- Tag -->
      <p>If you are not sure who to contact, try <a href="mailto:editors@ink19.com">editors@ink19.com</a>.</p>

    </template>


    <!-- Right - Staff Listing -->
    <template #right>

      <!-- Heading -->
      <j-heading heading="Staff" />

      <!-- Table -->
      <StaffTable
        :staff="pageListing"
        @paginate="handlePaginate"
      />

    </template>

    <!-- Dialogs -->
    <template #dialogs>

      <!-- Add Staff -->
      <AddStaffDialog />

    </template>  
  </j-page>
</template>

<script>

// Components
import AddStaffDialog from '@/components/Staff/AddStaffDialog'
import MastheadEntry from '@/components/Staff/MastheadEntry'
import StaffTable from '@/components/Staff/StaffTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions, mapMutations } = createNamespacedHelpers('staff')

export default {
  name: 'StaffDirectoryView',
  // -------------
  // Components ==
  // -------------
  components: {
    AddStaffDialog,
    MastheadEntry,
    StaffTable
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(to, _from, next) {
    next(vm => {
      if (vm.searchMode) {
        vm.fetchQuery({
          p: to.query.p,
          q: to.query.q
        })
      } else {
        vm.$store.dispatch('staff/fetchEditors')
        vm.$store.dispatch('staff/fetchStaff')
      }
    })
  },
  beforeRouteUpdate (to, _from, next) {
    if (this.searchMode) {
      this.fetchQuery({
        p: to.query.p,
        q: to.query.q
      })
    }
    next()
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: this.searchMode ? `Staff [matching ${this.query.q}]` : 'Staff Directory'
    }
  },
  data() {
    return {
      mode: 'directory'      
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // Vuex
    ...mapState([
      'editors',
      'staff',
      'query'
    ]),
    ...mapGetters([
      'loading'
    ]),
    pageTitle () {
      return this.searchMode ? 'Staff' : 'Staff Directory'
    },
    pageSubtitle () {
      return this.searchMode && this.query.q ? `matching &laquo;${this.query.q}&raquo;` : ''
    },
    pageListing () {
      return this.searchMode ? this.query : this.staff
    },
    directoryMode () { return this.mode === 'directory' },
    searchMode () { return this.mode === 'search' },
    leftCols () {
      return this.showMasthead ? 4 : 0
    },
    showMasthead () {
      return this.directoryMode
    },
    // TODO: Normalize policy
    canAddStaff () {
      return this.$user.isAdmin
    },
    // Helpers
    pageQuery () {
      return {
        p: this.query.p,
        q: this.query.q
      }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Vuex
    ...mapActions([
      'fetchEditors',
      'fetchStaff',
      'fetchQuery'
    ]),
    ...mapMutations([
      'NEW_CONTACT',
      'NEW_QUERY'
    ]),
    // Handlers
    handleExpand () {
      this.NEW_QUERY()
      this.mode = 'search'
    },
    handleSearch (query) {
      this.query.q = query
      this.query.p = 1
      this.fetchQuery(this.pageQuery)
    },
    handleClear () {
      this.mode = 'directory'
    },
    handlePaginate (page) {
      this.fetchStaff(page)
    },
    handleAddStaff () {
      this.NEW_CONTACT()
      this.$dialog.open('add-staff')
    }
  }
}
</script>
