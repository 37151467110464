<template>
  <j-table
    class="staff-table"
    :listing="staff"
    items="staff"
    :heading="heading"
    @paginate="handlePaginate"
  >
    <!-- Actions -->
    <template #actions>
      <slot name="actions" />
    </template>

    <!-- Header -->
    <template #header>
      <tr>
        <th>Name</th>
        <th>Location</th>
        <th v-if="$user.isAdmin">
          Email
        </th>
        <th>Latest</th>
        <th v-if="$user.isAdmin">Last Login</th>
        <th v-if="$user.isAdmin">&nbsp;</th>
      </tr>
    </template>

    <!-- Listing -->
    <template #listing>
      <tr
        v-for="contact in staff.getModels()"
        :key="contact.slug"
      >
        <!-- Full Name -->
        <td>
          <strong><router-link :to="{ name: 'contributor-view', params: { slug: contact.slug }}">
              <j-icon
                v-if="contact.deleted_at"
                icon="ban"
                class="mr-1"
                color="red"
              />
              {{ contact.name }}
            </router-link>
            <j-icon
              v-if="contact.isAdmin"
              icon="tools"
              class="ml-1"
            />
            <j-icon
              v-if="contact.isEditor"
              icon="pen-fancy"
              class="ml-1"
            /></strong>
        </td>

        <!-- Location -->
        <td v-html="contact.location" />

        <!-- Admin Actions -->
        <td v-if="$user.isAdmin">
          <a :href="`mailto:${contact.email}`">
            <j-icon
              icon="envelope"
              :title="contact.email"
            />
          </a>
        </td>

        <!-- Latest Item -->
        <td> 
          <router-link
            v-if="contact.latest_item.title"
            :to="{ name: 'articles-view', params: { tag19: contact.latest_item.tag19 }}"
          >
            {{ contact.latest_item.title | truncate(40) }}
          </router-link>
        </td>

        <!-- Last Login -->
        <td v-if="$user.isAdmin">
          {{ contact.latest_login | longDate }}
        </td>

        <!-- Admin Actions -->
        <td v-if="$user.isAdmin"> 
          <j-button
            icon="eye"
            type="icon"
            :help="`Log in as ${contact.name}`"
            :to="{ name: 'alias', params: { slug: contact.slug }}"
          />
        </td>
      </tr>
    </template>
  </j-table>
</template>

<script>

export default {
  name: 'StaffTable',
  // -------------
  // Components ==
  // -------------
  components: {
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    heading: {
      type: String,
      default: ''
    },
    staff: {
      type: Object,
      default: null
    },
    hide: {
      type: Array,
      default: () => []
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    show(column) {
      if (this.hide) {
        return !this.hide.includes(column)
      } else {
        return true
      }
    },
    handlePaginate(page) {
      this.$emit('paginate', page)
    }
  }
}
</script>
