<template>

  <!-- Masthead Entry -->
  <j-card
    class="masthead-entry"
  >
    <j-card-text class="pa-3">
      <v-container
        fluid
        class="pa-0"
      >
        <v-row no-gutters>
          <v-col
            cols="3"
            class="mr-4"
          >

            <!-- Badge -->
            <j-avatar
              :source="contact.badge_src"
              :alt="contact.initials"
              class="show-pointer"
              @click.native="handlePortrait"
            />
          </v-col>
          <v-col>

            <!-- Actions -->
            <slot name="actions" />

            <!-- Name -->
            <h2 class="staff-name">
              {{ contact.getName }}
            </h2>

            <!-- Title -->
            <p class="staff-title mb-1">
              {{ contact.title }}
            </p>

            <!-- Contact -->
            <p class="staff-contact mb-0">
              <a :href="`mailto:${contact.email}`">
                <j-icon
                  icon="envelope"
                  :title="contact.email"
                  class="mr-1"
                />
                {{ contact.email }}
              </a>
              &bull;
              <router-link :to="{ name: 'contributor-view', params: { slug: contact.slug }}">
                Profile
              </router-link>
            </p>

          </v-col>
        </v-row>
      </v-container>
    </j-card-text>
  </j-card>
</template>

<script>
export default {
  name: 'MastheadEntry',
  // -------------
  // Properties ==
  // -------------
  props: {
    contact: {
      type: Object,
      default: null
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Handlers
    handlePortrait () {
      this.$emit('portrait')
    }
  }
}
</script>

<style lang="scss">

.masthead-entry {

  .staff-name {
    @extend %j-headline;
    font-weight: bold;
  }

  .staff-title {
    @extend %j-body;
  }

  .staff-contact {
    a {
      text-decoration: none;
    }
  }

}

</style>
