<template>
  <!-- Modal for adding staff  -->
  <j-dialog
    dialog-name="add-staff"
    :value="$dialog.status('add-staff')"
    title="Add Staff"
    max-width="50%"
    @cancel="handleCloseDialog"
  >
    <j-card-text>
      <!-- Success -->
      <j-alert
        v-if="staffCreated"
        type="success"
        message="The staff record has been added and an invitation sent."
        class="mt-4"
      />

      <j-form v-else model="staff.contact">

        <!-- First Name -->
        <j-control
          name="first_name"
          cols="12"
          sm="4"
        />

        <!-- Last Name -->
        <j-control
          name="last_name"
          cols="12"
          sm="4"
        />

        <!-- Email -->
        <j-control
          name="email"
          cols="12"
          sm="4"
        />

      </j-form>
    </j-card-text>

    <!-- Actions -->
    <template #actions>

      <!-- Create -->
      <j-button
        v-if="!staffCreated"
        label="create"
        :disabled="contact.pristine"
        :processing="contact.saving"
        @action="handleAddStaff"
      />
      <!-- Close -->
      <j-button
        v-else
        label="Close"
        @action="handleCloseDialog"
      />
    </template>

    <!-- Options -->
    <template #options>
      <!-- Cancel -->
      <j-button
        v-if="!(contact.saving || staffCreated)"
        label="Close"
        type="option"
        @action="handleCloseDialog"
      />
    </template>
  </j-dialog>
</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('staff')

export default {
  name: 'AddStaff',
  data () {
    return {
      staffCreated: false
    }
  },
  computed: {
    ...mapState([
      'showAddStaffDialog',
      'contact'
    ]),
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    ...mapActions([
      'saveContact',
    ]),
    handleCloseDialog () {
      this.staffCreated = false
      this.$dialog.close('add-staff')
    },
    handleAddStaff() {
      this.saveContact().then(() => this.staffCreated = this.contact.isValid)
    }
  }
}
</script>
